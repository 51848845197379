import request from './request.js';
import public_request from './public_request';
//获取系统设置
export function system() {
    return request({
        url: '/setting/system',
        method: 'GET',
        params: public_request(),
    });
}
//修改常用设置
export function acount({ loginPrefix, mobileVerifySwitch }) {
    return request({
        url: '/setting/system/account',
        method: 'put',
        data: public_request({ loginPrefix, mobileVerifySwitch }),
    });
}
//修改线索池设置
export function clue_pool({ workingDistributeClueFollowTimeOfMinutes, unconfirmedClueFollowTimeOfHours, caughtClueFollowTimeOfHours, transferredClueFollowTimeOfHours, nonworkingDistributeClueFollowTimeOfHours, unCountExistClientApplyingWithinOfDay }) {
    return request({
        url: '/setting/system/clue-pool',
        method: 'put',
        data: public_request({
            workingDistributeClueFollowTimeOfMinutes,
            unconfirmedClueFollowTimeOfHours,
            caughtClueFollowTimeOfHours,
            transferredClueFollowTimeOfHours,
            nonworkingDistributeClueFollowTimeOfHours,
            unCountExistClientApplyingWithinOfDay,
        }),
    });
}
//修改客户管理设置
export function client_management({ staffOrdinaryClientMax, staffImportantClientMax, ordinaryClientTransferPublicFollowTimeOfDay }) {
    return request({
        url: '/setting/system/client-management',
        method: 'put',
        data: public_request({ staffOrdinaryClientMax, staffImportantClientMax, ordinaryClientTransferPublicFollowTimeOfDay }),
    });
}

export function updateSystem(data) {
    return request({
        url: '/setting/system',
        method: 'put',
        data: public_request(data),
    });
}
