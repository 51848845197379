<template>
    <div v-loading="loading" class="system">
        <div class="main">
            <!-- 常用设置 -->
            <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="47%"
                class="demo-ruleForm"
            >
                <div class="normal">
                    <span class="title">常用设置</span>
                    <el-card class="normal-card">
                        <el-form-item label="登陆账号前缀：" prop="loginPrefix">
                            <el-input
                                v-model="ruleForm.loginPrefix"
                                size="small"
                                style="width: 170px"
                            />
                        </el-form-item>
                        <el-form-item label="验证码登陆：">
                            <el-switch
                                v-model="ruleForm.mobileVerifySwitch"
                                :active-value="1"
                                :inactive-value="0"
                            />
                        </el-form-item>
                    </el-card>
                </div>
            </el-form>

            <!-- 线索池设置 -->
            <div class="cule-pond">
                <div class="title">
                    <div style="display:flex;margin-right:2px">线索池</div>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="如果线索存在关联客户，且线索与客户都是即将超时，以剩余时间最短的为准"
                        placement="right"
                    >
                        <img
                            src="@/assets/setting/tipIcon.png"
                            alt
                            style="width: 20px;height: 20px;"
                        />
                    </el-tooltip>
                </div>

                <!-- 线索池表单设置 -->
                <el-card class="cule-card">
                    <el-form
                        ref="ruleForm1"
                        :model="ruleForm1"
                        :rules="rules"
                        class="demo-ruleForm"
                        label-width="47%"
                    >
                        <el-form-item
                            label="上班时分配的新线索跟进时间："
                            prop="workingDistributeClueFollowTimeOfMinutes"
                        >
                            <!-- <el-input
                placeholder
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="ruleForm2.workingDistributeClueFollowTimeOfMinutes"
                size="small"
                style="width: 170px "
              >
                <template slot="append">分钟</template>
                            </el-input>-->
                            <el-input
                                v-model="ruleForm1.workingDistributeClueFollowTimeOfMinutes"
                                style="width: 170px "
                                size="small"
                                placeholder
                                oninput="value=value.replace(/[^\d]/g,'')"
                            >
                                <template slot="append">分钟</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="未确认线索跟进时间：" prop="unconfirmedClueFollowTimeOfHours">
                            <el-input
                                v-model="ruleForm1.unconfirmedClueFollowTimeOfHours"
                                size="small"
                                style="width: 170px "
                                placeholder
                                oninput="value=value.replace(/[^\d]/g,'')"
                            >
                                <template slot="append">小时</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="抓取线索跟进时间：" prop="caughtClueFollowTimeOfHours">
                            <el-input
                                v-model="ruleForm1.caughtClueFollowTimeOfHours"
                                size="small"
                                placeholder
                                oninput="value=value.replace(/[^\d]/g,'')"
                                style="width: 170px "
                            >
                                <template slot="append">小时</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="转移线索跟进时间：" prop="transferredClueFollowTimeOfHours">
                            <el-input
                                v-model="ruleForm1.transferredClueFollowTimeOfHours"
                                size="small"
                                placeholder
                                oninput="value=value.replace(/[^\d]/g,'')"
                                style="width: 170px "
                            >
                                <template slot="append">小时</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="下班、周末、节假日分配的新线索跟进时间（上班时间起算）："
                            prop="nonworkingDistributeClueFollowTimeOfHours"
                        >
                            <el-input
                                v-model="ruleForm1.nonworkingDistributeClueFollowTimeOfHours"
                                size="small"
                                placeholder
                                oninput="value=value.replace(/[^\d]/g,'')"
                                style="width: 170px "
                            >
                                <template slot="append">小时</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            label="老客户多少天内重复申请不计线索条数："
                            prop="unCountExistClientApplyingWithinOfDay"
                        >
                            <el-input
                                v-model="ruleForm1.unCountExistClientApplyingWithinOfDay"
                                size="small"
                                placeholder
                                oninput="value=value.replace(/[^\d]/g,'')"
                                style="width: 170px "
                            >
                                <template slot="append">天</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="部门池超时时间：" prop="departmentPoolTimeoutOfDay">
                            <el-input
                                v-model="ruleForm1.departmentPoolTimeoutOfDay"
                                size="small"
                                style="width: 170px "
                            >
                                <template slot="append">天</template>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </el-card>
            </div>
            <!-- 客户管理设置 -->
            <div class="cule-pond">
                <div class="title">
                    <div style="display:flex;margin-right:2px">客户管理设置</div>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="如果客户存在关联线索，且线索与客户都是即将超时，以剩余时间最短的为准"
                        placement="right"
                    >
                        <img
                            src="@/assets/setting/tipIcon.png"
                            alt
                            style="width: 20px;height: 20px;"
                        />
                    </el-tooltip>
                </div>
                <el-card class="cule-card">
                    <el-form ref="ruleForm2" :rules="rules" :model="ruleForm2" label-width="47%">
                        <el-form-item id="normal" label="员工名下普通客户上限：" prop="staffOrdinaryClientMax">
                            <div class="flex">
                                <el-input
                                    v-model="ruleForm2.staffOrdinaryClientMax"
                                    size="small"
                                    style="width: 170px "
                                >
                                    <template slot="append">个</template>
                                </el-input>
                                <el-tooltip class="item" effect="dark" placement="right">
                                    <!-- content="客户上限只包括“主动抓取”客户（包括抓取线索时关联的老客户），其他线索转客户、分配、转移等“被动得到”的客户没有上限。" -->
                                    <div slot="content">客户数量达到上限时，已经分配的新线索仍然可以转化为客户</div>
                                    <img
                                        src="@/assets/setting/tipIcon.png"
                                        alt
                                        style="width: 20px;height: 20px;"
                                    />
                                </el-tooltip>
                            </div>
                        </el-form-item>
                        <el-form-item
                            id="important"
                            label="员工名下重要客户上限："
                            prop="staffImportantClientMax"
                        >
                            <el-input
                                v-model="ruleForm2.staffImportantClientMax"
                                size="small"
                                style="width: 170px "
                                oninput="value=value.replace(/[^\d]/g,'')"
                            >
                                <template slot="append">个</template>
                            </el-input>
                        </el-form-item>

                        <el-form-item
                            label="普通客户超时未跟进流入公海时间："
                            prop="ordinaryClientTransferPublicFollowTimeOfDay"
                        >
                            <el-input
                                v-model="ruleForm2.ordinaryClientTransferPublicFollowTimeOfDay"
                                size="small"
                                style="width: 170px "
                            >
                                <template slot="append">天</template>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="部门海超时时间：" prop="departmentSeaTimeoutOfDay">
                            <el-input
                                v-model="ruleForm2.departmentSeaTimeoutOfDay"
                                size="small"
                                style="width: 170px "
                            >
                                <template slot="append">天</template>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </el-card>
            </div>

            <!-- 添加微信设置 -->
            <div class="addWxSet">
                <div class="addWxSet-title">添加微信设置</div>
                <el-card>
                    <el-form
                        ref="WxSetForm"
                        class="demo-ruleForm"
                        label-width="47%"
                        :model="WxSetForm"
                        :rules="WxSetFormRules"
                    >
                        <el-form-item
                            id="staffAddWxUpperlimit"
                            label="一个员工一天可添加微信上限："
                            prop="staffAddWxUpperlimit"
                        >
                            <el-input
                                v-model.number="WxSetForm.staffAddWxUpperlimit"
                                style="width: 170px"
                                size="small"
                                oninput="value=value.replace(/[^\d]/g,'')"
                            >
                                <template slot="append">个</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            id="WxAddedUpperlimit"
                            label="一个客户微信一天内被添加次数上限："
                            prop="WxAddedUpperlimit"
                        >
                            <el-input
                                v-model.number="WxSetForm.WxAddedUpperlimit"
                                style="width: 170px"
                                size="small"
                                oninput="value=value.replace(/[^\d]/g,'')"
                            >
                                <template slot="append">次</template>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </el-card>
            </div>
        </div>

        <div class="btn">
            <el-button type="primary" size="small" @click="submitForm()">保存</el-button>
            <el-button size="small" @click="reset">恢复默认</el-button>
        </div>
    </div>
</template>

<script>
import { system, updateSystem } from '@/api/setting_system.js';
export default {
    data() {
        let staffAddWxUpperlimitRules = (rule, value, callback) => {
            if (0 <= value && value <= 100) {
                callback();
            } else {
                callback(new Error('请输入1-100之间的数字'));
            }
        };
        let WxAddedUpperlimitRules = (rule, value, callback) => {
            if (0 <= value && value <= 10) {
                callback();
            } else {
                callback(new Error('请输入1-10之间的数字'));
            }
        };
        return {
            // 表单
            ruleForm: {
                loginPrefix: '',
                mobileVerifySwitch: 0,
            },
            ruleForm1: {
                workingDistributeClueFollowTimeOfMinutes: '30',
                unconfirmedClueFollowTimeOfHours: '24',
                caughtClueFollowTimeOfHours: '24',
                transferredClueFollowTimeOfHours: '24',
                nonworkingDistributeClueFollowTimeOfHours: '10',
                unCountExistClientApplyingWithinOfDay: '30',
                departmentPoolTimeoutOfDay: '3',
            },
            ruleForm2: {
                staffOrdinaryClientMax: '200',
                staffImportantClientMax: '50',
                ordinaryClientTransferPublicFollowTimeOfDay: '3',
                departmentSeaTimeoutOfDay: '3',
            },
            // 微信设置表单
            WxSetForm: {
                staffAddWxUpperlimit: 30, //一个员工一天可添加微信上限：
                WxAddedUpperlimit: 3, //一个客户微信一天内被添加次数上限
            },
            // 微信设置表单规则
            WxSetFormRules: {
                staffAddWxUpperlimit: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { type: 'number', message: '必须为数字值' },
                    { validator: staffAddWxUpperlimitRules, trigger: 'blur' },
                ],
                WxAddedUpperlimit: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { type: 'number', message: '必须为数字值' },
                    { validator: WxAddedUpperlimitRules, trigger: 'blur' },
                ],
            },
            // 表单规则
            rules: {
                loginPrefix: [
                    {
                        required: true,
                        message: '请输入登陆账号前缀',
                        trigger: 'blur',
                    },
                    {
                        min: 5,
                        max: 10,
                        message: '长度是5-10个字符之间',
                        trigger: 'blur',
                    },
                    { validator: this.formValidation.prefix, trigger: 'blur' },
                ],
                workingDistributeClueFollowTimeOfMinutes: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.wdqrl, trigger: 'blur' },
                ],
                unconfirmedClueFollowTimeOfHours: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.ydyeq, trigger: 'blur' },
                ],
                caughtClueFollowTimeOfHours: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.ydyeq, trigger: 'blur' },
                ],
                transferredClueFollowTimeOfHours: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.ydyeq, trigger: 'blur' },
                ],
                nonworkingDistributeClueFollowTimeOfHours: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.ydyeq, trigger: 'blur' },
                ],
                unCountExistClientApplyingWithinOfDay: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.ldq, trigger: 'blur' },
                ],
                departmentPoolTimeoutOfDay: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.ydyeq, trigger: 'blur' },
                ],
                staffOrdinaryClientMax: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.ldyw, trigger: 'blur' },
                ],
                staffImportantClientMax: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.ldyw, trigger: 'blur' },
                ],
                ordinaryClientTransferPublicFollowTimeOfDay: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.ydyeq, trigger: 'blur' },
                ],
                departmentSeaTimeoutOfDay: [
                    { required: true, message: '请输入内容', trigger: 'blur' },
                    { validator: this.formValidation.ydyeq, trigger: 'blur' },
                ],
            },
            loading: false,
        };
    },
    created() {
        this.getFormInfo();
    },
    mounted() {
        document.getElementById('normal').firstChild.innerHTML =
            '员工名下<span style="color:red">普通</span>客户上限：';
        document.getElementById('important').firstChild.innerHTML =
            '员工名下<span style="color:red">重要</span>客户上限：';
        document.getElementById('staffAddWxUpperlimit').firstChild.innerHTML =
            '一个员工一天可<span style="color:red">添加微信</span>上限：';
        document.getElementById('WxAddedUpperlimit').firstChild.innerHTML =
            '一个客户微信一天内被<span style="color:red">添加次数</span>上限：';
    },
    methods: {
        getFormInfo() {
            system().then((res) => {
                this.loading = true;
                console.log(res);
                // 登陆前缀
                this.ruleForm.loginPrefix = res.data.loginPrefix;
                // 验证码开关
                this.ruleForm.mobileVerifySwitch = res.data.mobileVerifySwitch;
                // 上班时分配的线索跟进时间
                this.ruleForm1.workingDistributeClueFollowTimeOfMinutes =
                    res.data.workingDistributeClueFollowTimeOfMinutes;
                // 未确认线索跟进时间
                this.ruleForm1.unconfirmedClueFollowTimeOfHours =
                    res.data.unconfirmedClueFollowTimeOfHours;
                // 抓取线索跟进时间
                this.ruleForm1.caughtClueFollowTimeOfHours =
                    res.data.caughtClueFollowTimeOfHours;
                // 转移线索跟进时间
                this.ruleForm1.transferredClueFollowTimeOfHours =
                    res.data.transferredClueFollowTimeOfHours;
                // 非上班时分配的线索跟进时间
                this.ruleForm1.nonworkingDistributeClueFollowTimeOfHours =
                    res.data.nonworkingDistributeClueFollowTimeOfHours;
                // 老客户多少天内申请不计入条数
                this.ruleForm1.unCountExistClientApplyingWithinOfDay =
                    res.data.unCountExistClientApplyingWithinOfDay;
                // 部门池超时时间
                this.ruleForm1.departmentPoolTimeoutOfDay =
                    res.data.departmentPoolTimeoutOfDay;
                // 员工客户数量上限
                this.ruleForm2.staffOrdinaryClientMax =
                    res.data.staffOrdinaryClientMax;
                // 员工重要客户数量上限
                this.ruleForm2.staffImportantClientMax =
                    res.data.staffImportantClientMax;
                // 普通客户未跟进流入公海时间
                this.ruleForm2.ordinaryClientTransferPublicFollowTimeOfDay =
                    res.data.ordinaryClientTransferPublicFollowTimeOfDay;
                // 部门海超时时间
                this.ruleForm2.departmentSeaTimeoutOfDay =
                    res.data.departmentSeaTimeoutOfDay;
                // 一个员工一天可添加微信上限
                this.WxSetForm.staffAddWxUpperlimit =
                    res.data.dailyStaffAddWechatMax;
                // 一个客户微信一天内被添加次数上限
                this.WxSetForm.WxAddedUpperlimit =
                    res.data.dailySameWechatAddMax;
                setTimeout(() => {
                    this.loading = false;
                }, 1000);
            });
        },
        submitForm() {
            // 验证三个表单
            let baseForm = false,
                clueForm = false,
                clientForm = false,
                WxSetForm = false;

            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    baseForm = true;
                }
            });
            this.$refs.ruleForm1.validate((valid) => {
                if (valid) {
                    clueForm = true;
                }
            });
            this.$refs.ruleForm2.validate((valid) => {
                if (valid) {
                    clientForm = true;
                }
            });
            // 验证微信表单
            this.$refs.WxSetForm.validate((valid) => {
                if (valid) WxSetForm = true;
            });

            if (baseForm && clueForm && clientForm && WxSetForm) {
                // alert("可以提交");
                let data = {
                    // 第一个表单组合
                    loginPrefix: this.ruleForm.loginPrefix,
                    mobileVerifySwitch: this.ruleForm.mobileVerifySwitch,
                    // 第二个表单组合
                    workingDistributeClueFollowTimeOfMinutes:
                        this.ruleForm1.workingDistributeClueFollowTimeOfMinutes,
                    unconfirmedClueFollowTimeOfHours:
                        this.ruleForm1.unconfirmedClueFollowTimeOfHours,
                    caughtClueFollowTimeOfHours:
                        this.ruleForm1.caughtClueFollowTimeOfHours,
                    transferredClueFollowTimeOfHours:
                        this.ruleForm1.transferredClueFollowTimeOfHours,
                    nonworkingDistributeClueFollowTimeOfHours:
                        this.ruleForm1
                            .nonworkingDistributeClueFollowTimeOfHours,
                    unCountExistClientApplyingWithinOfDay:
                        this.ruleForm1.unCountExistClientApplyingWithinOfDay,
                    departmentPoolTimeoutOfDay:
                        this.ruleForm1.departmentPoolTimeoutOfDay,
                    // 第三个表单组合
                    staffOrdinaryClientMax:
                        this.ruleForm2.staffOrdinaryClientMax,
                    staffImportantClientMax:
                        this.ruleForm2.staffImportantClientMax,
                    ordinaryClientTransferPublicFollowTimeOfDay:
                        this.ruleForm2
                            .ordinaryClientTransferPublicFollowTimeOfDay,
                    departmentSeaTimeoutOfDay:
                        this.ruleForm2.departmentSeaTimeoutOfDay,
                    dailyStaffAddWechatMax: this.WxSetForm.staffAddWxUpperlimit,
                    dailySameWechatAddMax: this.WxSetForm.WxAddedUpperlimit,
                };
                updateSystem(data).then((res) => {
                    if (res.code == 0) {
                        this.$message.success('修改成功');
                        this.getFormInfo();
                    }
                });
            } else {
                return false;
            }
        },
        // 重置
        reset() {
            this.ruleForm.mobileVerifySwitch = 0;
            this.$refs.ruleForm1.resetFields();
            this.$refs.ruleForm2.resetFields();
            this.$refs.WxSetForm.resetFields();
        },
    },
};
</script>

<style lang="less" scope>
.system {
    /* height: calc(100vh - 80px); */
    margin: 10px;
    overflow: hidden;
    height: calc(100vh - 83px);
    .main {
        height: calc(100vh - 150px);
        overflow-y: scroll;
    }
    .normal {
        .title {
            display: block;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .normal-card {
            /deep/ .el-card__body {
                // transform: translate(23%);
            }
        }
    }

    .cule-pond {
        margin-top: 10px;
        .title {
            display: flex;
            margin-bottom: 10px;
        }

        /deep/ .el-input-group__append {
            padding: 0;
            width: 46px;
            text-align: center;
        }
        .cule-card {
            /deep/ .el-card__body {
            }
        }
    }
    .btn {
        text-align: center;
        background-color: #fff;
        padding-bottom: 10px;
        padding-top: 10px;
        border-radius: 0 0 10px 10px;
        border-top: 1px solid #ccc;
        width: calc(100% - 7px);
    }
}
.flex {
    display: flex;
    align-items: center;
}
// 添加微信设置
.addWxSet-title {
    margin: 10px 0 10px 0;
}
</style>
